import React from "react";
import { Container, Row, Col, Table, Button,ButtonGroup } from "react-bootstrap";
import { ItemValue } from "survey-react";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import HowToRegIcon from '@mui/icons-material/HowToReg';
const UserInfo = ({
  user,
  profile,
  action,
  allRequests,
  allowSendRequest,
  manageRequest,
}) => {
  // let me = JSON.parse(localStorage.getItem('user'))
  // console.log("local storage:"+ me?.role2)
  // console.log("user info:"+JSON.stringify(user))


  function getEachSurveyParam(surveys,param){
    // console.log("getEachSurveyParam:"+JSON.stringify(surveys))
    // console.log("param:"+param)
    let res = surveys?.map((i)=>{
      // console.log(i[param])
      return i[param]
    })

    return res
  }
  return (
    <Container className="text-left">
      {user && (
        <>
          <Row>
            <Col>
              <p>
                <strong>Email: </strong>
                {user.email}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                <strong>Status: </strong>
                {user.status}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                <strong>Role: </strong>
                {user.role}
              </p>
            </Col>
          </Row>
          <Row className="mb-3">
            {(user.role === "admin" || user.role === "experimenter") && (
              <Col>
                <Button
                  variant="warning"
                  className="rounded-pill px-3"
                  onClick={(e) => action("demote", e)}
                >
                  {"Demote to Participant"}
                </Button>
              </Col>
            )}

            {user.role !== "admin" && (
              <div className="mx-2">
                {/* <Col> */}
                {user.role !== "experimenter" && (
                  <Button
                    className="rounded-pill px-3 mr-3"
                    onClick={(e) => action("promoteExp", e)}
                  >
                    {"Promote to Experimenter"}
                  </Button>
                )}
                {/* </Col> */}

                {/* <Col> */}
                {
                  <Button
                    className="rounded-pill px-3"
                    onClick={(e) => action("promoteAdm", e)}
                    variant="success"
                  >
                    {"Promote to Admin"}
                  </Button>
                }
                {/* </Col> */}
              </div>
            )}
          </Row>
          <hr></hr>
          <Row>
            <Col>
              {/* <p> */}
              <strong>Data Request Access: </strong>

              {user.requestAllow ? (
                <div style={{ display: "inline" }}>
                  <HowToRegIcon /> Allowed
                </div>
              ) : (
                <div style={{ display: "inline" }}>
                  <NotInterestedIcon />
                  Not Allowed
                </div>
              )}
              {/* </p> */}
            </Col>
          </Row>
          {user.requestAllow &&
            allRequests &&
            allRequests.map((request) => {
              return (
                <div
                  key={request.urls[0]}
                  style={{
                    margin: "12px 0px",
                    padding: "14px 10px 0px 20px",
                    boxShadow: "0px 0px 8px #ccc",
                    borderRadius: "8px",
                    // whiteSpace:'nowrap',
                    // display:'inline',
                  }}
                >
                  <Row>
                    <Col>
                      <p>
                        <strong>Survey to request : </strong>
                        {/* {request.survey.label}  */}
                        {getEachSurveyParam(request.survey,"label")}

                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <p>
                        <strong>Agreement link : </strong>
                        <a
                          href={
                            "https://yourmorals-images.s3.us-west-2.amazonaws.com/" +
                            request.urls[0].replace(/\s/g, "%20")
                          }
                          target="_blank"
                        >
                          Click to review
                        </a>
                      </p>
                      {/* {"https://yourmorals-images.s3.us-west-2.amazonaws.com/"+request.urls[0].replace(/\s/g,'%20')} */}
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <p>
                        <strong>Rquest Time : </strong>
                        {new Date(request.createdAt).toLocaleString("en-US", {
                          timeZone: "America/Los_Angeles"
                        })}
                      </p>
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col>
                      <p>
                        <strong>Status : </strong>
                        {request.status}
                      </p>
                    </Col>
                    {request.status === "pending" && (
                      <Col className="mb-3">
                        <div style={{ display: "inline" }}>
                          <Button
                            variant="success"
                            className="rounded-pill mr-2"
                            onClick={(e) => manageRequest("allow", request._id)}
                          >
                            {"Allow"}
                          </Button>
                          <Button
                            variant="warning"
                            className="rounded-pill"
                            onClick={(e) => manageRequest("reject", request._id)}
                          >
                            {"Reject"}
                          </Button>
                        </div>
                      </Col>
                    )}
                    {request.status === "expired" && (
                      <Col className="mb-3">
                        <Button
                          variant="success"
                          className="rounded-pill mr-2"
                          onClick={(e) => manageRequest("allow", request._id)}
                        >
                          {"Extend"}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </div>
              );
            })}
          <Row className="mb-3">
            {!user.requestAllow && (
              <Col>
                <Button
                  variant="warning"
                  className="rounded-pill px-3"
                  onClick={(e) => allowSendRequest("allow", e)}
                >
                  {"Allow to request"}
                </Button>
              </Col>
            )}
          </Row>
        </>
      )}
      {profile && (
        <Row>
          <Col>
            <Table bordered hover>
              <tbody>
                {Object.keys(profile).map((k, i) => (
                  <tr key={k}>
                    <td>{k}</td>
                    <td>{profile[k]}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default UserInfo;
